export default function About({ props }) {
  return (
    <div className="about__container">
      <h1 className="topheading">About linguafranca</h1>
      {/* img of skyline and me in action up top.*/}
      <h3 className="about__beginning">In linguistics...</h3>
      <h4 className="about__beginning">
        A <span style={{ color: "#ff7e3d" }}>lingua franca</span> is a bridge
        language used by two people who don't have the same mother tongue as a
        way to connect. <br /> And one of the best ways we have as people to
        connect is through <span style={{ color: "#ff7e3d" }}>music</span>.
      </h4>
      <p className="about__para">This is my slice of that lingua franca.</p>
      <p className="about__para">
        {" "}
        I've been playing piano since I was five, and music has never left my
        life since. With this project, I've found a beautiful intersection of{" "}
        <span style={{ color: "#0099ff" }}>
          electronic music, complex lyricism, inherently groovy beats, and
          keyboard/synthesizer melodies that beckon back to the jazz of the 20th
          century.{" "}
        </span>{" "}
        My influences span from nu-soul (Lawrence, Melt), electro-funk (Chromeo,
        Breakbot), house (Daft Punk, LP Giobbi), UK garage (Higgo, Sammy Virji),
        and even Italian street pop (Frah Quintale, Ele A). By blending my
        classically trained ear and my passion for songwriting with today's
        sounds, I hope to make music for any listener: lyricists, fellow
        musicians, your Average Joe, and most importantly those who just want to
        dance.{" "}
      </p>
      <p className="about__para">
        Another reason for my stage name is that I am an aspiring polyglot - not
        every song is written in my native tongue of English, due to both
        my goal to reach as many people as possible and my love
        of learning languages. I grew up monolingual, but have learned Spanish
        and Italian to fluency. I've dabbled in others including Turkish,
        Portuguese, French, German, Greek, and more! If you meet me, feel free
        to speak to me in your native tongue and I'll do my best to speak it
        back to you or learn something along the way!
      </p>
      <h2>
        Thank you so much for listening - I hope you enjoy my music as much as I
        do making it. <span style={{ color: "#ff7e3d" }}>-linguafranca</span>
      </h2>
    </div>
  );
}
