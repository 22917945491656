const catalog = {
  counterfeit_remix: {
    name: "COUNTERFEIT (linguafranca remix)",
    type: "single",
    release_date: "2023-11-03T00:00:00",
    collab_artists: [
      { name: "Yak Mishra", link: "https://www.youtube.com/c/Yaktube" },
    ],
    platforms: {
      spotify:
        "https://open.spotify.com/track/6iQL6qYiBzFbAXZPbL9TZ8?autoplay=true",
      youtube:
        "https://www.youtube.com/playlist?list=OLAK5uy_k2UG2pKyUbijW0hEtRMg2nzOWQ1mkSn-E",
    },
  },
};

export default catalog;
