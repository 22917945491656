import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faBandcamp,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default function Contact({ props }) {
  return (
    <div class="contact__container">
      <h1 className="topheading">Get in touch!</h1>
      <p className="contact__para">
        Want to work with me or just say hi? Reach out via Instagram or email!
      </p>
      <div className="contact__socials">
        <a href="mailto:linguafrancamusic1@gmail.com">
          <FontAwesomeIcon icon={faEnvelope} className="contact__social" />
        </a>
        <a href="https://www.instagram.com/linguafrancamusic" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="contact__social" />
        </a>
      </div>
      <p className="contact__para">Listen on these platforms!</p>
      <div className="contact__socials">
        <a href="https://open.spotify.com/artist/16L4qVSdePL6JJKQfNTod1?si=83pz88ZMR3uPJYlqiyHUnA" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faSpotify} className="contact__social" />
        </a>
        <a href="https://bit.ly/linguafranca-yt" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faYoutube} className="contact__social" />
        </a>
        <a href="https://soundcloud.com/linguafranca-music" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faSoundcloud} className="contact__social" />
        </a>
        <a href="https://linguafranca1.bandcamp.com/album/waiting-for-you" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faBandcamp} className="contact__social" />
        </a>
      </div>
    </div>
  );
}
