import { useState } from "react";
import "./App.scss";
import "font-awesome/css/font-awesome.min.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Music from "./components/Music";
import Contact from "./components/Contact";

function App() {
  let [tab, setTab] = useState("home");
  let [mobileTabsShown, setMobileTabsShown] = useState(false);
  let [size] = useState([window.innerWidth, window.innerHeight]);

  let isMobile = size[0] <= 600 && size[1] <= 800;

  // function handleResize() {
  //   setSize(() => [window.innerWidth, window.innerHeight]);
  // }
  // window.addEventListener("resize", handleResize);

  function renderSwitch() {
    switch (tab) {
      case "home":
        return <Home isMobile={isMobile} />;
      case "about":
        return <About />;
      case "music":
        return <Music />;
      case "contact":
        return <Contact />;
      default:
        return "page not found";
    }
  }

  return (
    <div className="App">
      <Header isMobile={isMobile}>
        {/* <h1 className="header__title">linguafranca</h1> */}
        <div className={"header__tabs" + (isMobile ? "-mobile" : "")}>
          <span onClick={() => setMobileTabsShown((t) => !t)}>
            <img
              className="header__tabs-logoimg"
              src="./linguafrancavector.png"
              alt="logo"
            />
          </span>
          <span
            onClick={() => {
              setTab("home");
              setMobileTabsShown((t) => !t);
            }}
            className={
              "header__tab" +
              (tab === "home" ? "-active" : "") +
              (isMobile ? "-mobile" : "") +
              (isMobile && !mobileTabsShown ? "-disabled" : "")
            }
          >
            home
          </span>
          <span
            onClick={() => {
              setTab("about");
              setMobileTabsShown((t) => !t);
            }}
            className={
              "header__tab" +
              (tab === "about" ? "-active" : "") +
              (isMobile ? "-mobile" : "") +
              (isMobile && !mobileTabsShown ? "-disabled" : "")
            }
          >
            about
          </span>
          <span
            onClick={() => {
              setTab("music");
              setMobileTabsShown((t) => !t);
            }}
            className={
              "header__tab" +
              (tab === "music" ? "-active" : "") +
              (isMobile ? "-mobile" : "") +
              (isMobile && !mobileTabsShown ? "-disabled" : "")
            }
          >
            music
          </span>
          <span
            onClick={() => {
              setTab("contact");
              setMobileTabsShown((t) => !t);
            }}
            className={
              "header__tab" +
              (tab === "contact" ? "-active" : "") +
              (isMobile ? "-mobile" : "") +
              (isMobile && !mobileTabsShown ? "-disabled" : "")
            }
          >
            contact
          </span>
        </div>
      </Header>
      {<div className="main">{renderSwitch()}</div>}
      <Footer>
        <p>&copy; 2023 linguafranca</p>
        <p>
          {
            "site made & managed by linguafranca - my day job is a software developer ;)"
          }
        </p>
      </Footer>
    </div>
  );
}

export default App;
