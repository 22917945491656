export default function Music({ props }) {
  return (
    <div>
      <h1 className="topheading">Check out my music!</h1>
      <iframe
        title="spotify"
        src="https://open.spotify.com/embed/artist/16L4qVSdePL6JJKQfNTod1?utm_source=generator"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        className="music__spotifyembed"
      ></iframe>
      <iframe
        title="soundcloud"
        className="music__soundcloudembed"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1564610969&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
      <iframe
        title="bandcamp"
        className="music__bandcampembed"
        src="https://bandcamp.com/EmbeddedPlayer/album=1762939183/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
        seamless
      >
        <a href="https://linguafranca1.bandcamp.com/album/waiting-for-you">
          Waiting For You by linguafranca
        </a>
      </iframe>
      <div className="music__catalog">
        {/* here add a catalog, mapped table rows of every song, spotify link, album/single name, release date, language written in (dictated by flag).*/}
      </div>
    </div>
  );
}
