import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ParseISO from "../utils/ParseISO";
import catalog from "../utils/catalog";
import allshows from "../utils/allshows";

export default function Home(props) {
  const { isMobile } = props;

  //shows yet to come
  let shows = allshows.filter((s) => ParseISO(s.date) > Date.now());

  let newRelease = "counterfeit_remix";
  let date = newRelease
    ? ParseISO(catalog[`${newRelease}`].release_date)
    : null;

  return (
    <div className={"home__container" + (isMobile ? "-mobile" : "")}>
      <div
        className={"home__subcontainer" + (isMobile ? "-mobile" : "")}
        id="release"
      >
        <h2 className="topheading">Newest Release</h2>
        {newRelease ? (
          <div>
            <img
              className="home__subcontainer-photo"
              alt="release album"
              src={"/covers/" + newRelease + ".png"}
            />
            <p>
              <a
                href={catalog[`${newRelease}`].platforms.spotify}
                target="_blank"
                rel="noreferrer"
              >
                {catalog[`${newRelease}`].name}
              </a>
            </p>
            {catalog[`${newRelease}`].collab_artists ? (
              <p>
                with{" "}
                {catalog[`${newRelease}`].collab_artists.map((s) => (
                  <a href={s.link} target="_blank" rel="noreferrer">
                    {s.name}{" "}
                  </a>
                ))}
              </p>
            ) : (
              ""
            )}
            <p>Released {date.toLocaleDateString()}</p>
          </div>
        ) : (
          <h2>Coming soon!</h2>
        )}
      </div>
      <div
        className={"home__subcontainer" + (isMobile ? "-mobile" : "")}
        id="show"
      >
        <h2 className="topheading">Upcoming Shows</h2>
        {shows.map((s) => (
          <div className="home__subcontainer-show">
            <span className="home__subcontainer-show-text">
              {s.venue} <br /> {s.city} <br />
              {ParseISO(s.date).toLocaleDateString()} |{" "}
              {ParseISO(s.date).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
            <span>
              <a href={s.link} target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faTicket}
                  className="home__subcontainer-show-ticket"
                />
              </a>
            </span>
          </div>
        ))}
        {shows.length === 0 && <h2>No upcoming shows yet... stay tuned!</h2>}
      </div>
    </div>
  );
}
